/* restore the default css that tailwindcss reset */
.wysiwyg > h1 {
  display: block !important;
  font-size: 2em !important;
  margin-top: 0.67em !important;
  margin-bottom: 0.67em !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  font-weight: bold !important;
}

.wysiwyg > h2 {
  display: block !important;
  font-size: 1.5em !important;
  margin-top: 0.83em !important;
  margin-bottom: 0.83em !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  font-weight: bold !important;
}

.wysiwyg > h3 {
  display: block !important;
  font-size: 1.17em !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  font-weight: bold !important;
}

.wysiwyg > blockquote {
  margin-right: 50px !important;

  font-size: 20px !important;
  text-transform: uppercase !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  /* margin-left: 50px !important; */
  padding-left: 15px !important;
  border-left: 3px solid theme("colors.primary") !important;
  display: inline-flex !important;
}

.wysiwyg > p {
  display: block !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.wysiwyg > ul {
  display: block !important;
  list-style-type: disc !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 40px !important;
}

.wysiwyg > li {
  display: list-item !important;
}

.wysiwyg > ol {
  display: block !important;
  list-style-type: decimal !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 40px !important;
}

.wysiwyg > a {
  color: theme("colors.primary");
}

.wysiwyg {
  text-align: justify;
}
