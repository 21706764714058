@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg-color: theme("colors.bg");
  --text-white: theme("colors.text");
  --primary-color: theme("colors.primary");
  --secondary-color: theme("colors.secondary");
  --third-color: theme("colors.third");
}

html,
body {
  background: var(--bg-color);
  color: var(--text-white);
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif;
  max-width: 100vw;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentContainer {
  margin: 0 5px;
  padding: 8px;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
