.profileColumn {
    @apply bg-primary bg-opacity-90 text-profile-text text-opacity-90;
    width: 100%;
    padding-top: 75px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 15px;

    display: flex;
    justify-content: space-between;

    border-radius: 25px;
}

.profileColumn > .leftSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profileColumn > .leftSection > .fullname {
    font-size: xx-large;
    display: flex;
    flex-direction: column;
}

.profileColumn > .leftSection > .job-names {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
}

.profileColumn > .leftSection > .job-names > .job-name {
    border-color: theme('colors.white');
    border-width: 1px;
    border-style: solid;
    padding: 5px;
    padding-left: 8px;
    border-radius: 50px;
    margin-right: 5px;
    font-size: small;
    white-space: nowrap;
    margin-top: 5px;
}

.profileColumn > .rightSection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileColumn > .rightSection > .profile-photo {
    width: 152px;
    border-radius: 100%;
    margin-bottom: 10px;
}